import { formatDate, getSnapDoc } from "@/assets/utils/common.utils";
import { mapDocs, sortAlpha } from "@/assets/utils/doc.utils";
import {
  auth,
  colClientPaymentMethods,
  colClientStatusTypes,
  colPaymentMethods,
  colRoles,
  colTeamSchedules,
  colUsers,
} from "@/assets/utils/firebase.utils";
import { onAuthStateChanged } from "firebase/auth";
import {
  doc,
  getCountFromServer,
  onSnapshot,
  query,
  where,
  orderBy,
} from "firebase/firestore";

let unsubscribeUser = null;
let unsubscribeUsers = null;
let unsubscribeRoles = null;
let unsubscribeClientStatus = null;
let unsubscribePaymentMethods = null;
let unsubscribeRole = null;
let unsubscribeClientPaymentMethods = null;
export default {
  state: {
    uid: null,
    user: {},
    _permissions: {},
    _filters: {},
    _roles: [],
    users: [],
    _clientStatusTypes: [],
    _paymentMethods: [],
    _clientPaymentMethods: [],
    _userTaskCount: 0,
  },
  getters: {
    uid: (state) => state.uid,
    user: (state) => {
      return {
        ...state.user,
        permissions: state._permissions,
      };
    },
    roles: (state) => state._roles,
    filters: (state) => state._filters,
    paymentMethods: (state) => sortAlpha(state._paymentMethods),
    clientPaymentMethods: (state) => sortAlpha(state._clientPaymentMethods),
    members: (state) => sortAlpha(state.users),
    isLogin: (state) => state.uid != null,
    clientStatusTypes: (state) => sortAlpha(state._clientStatusTypes),
    getClientStatusColor: (state) => (value) => {
      let status = state._clientStatusTypes.find((i) => i.value == value);
      return { background: status ? status.color : "black" };
    },
    userEmail: () => auth.currentUser.email || "",
    getUpdatedBy: (state) => (email) => {
      let user = state.users?.find(
        (i) => i.email.trim().toLowerCase() == email?.trim().toLowerCase()
      );
      return user ? user.name : email;
    },
    mapUser: () => (user) => {
      let { first_name, last_name, updated_at } = user.data();
      return {
        ...user.data(),
        name: `${first_name} ${last_name}`,
        updated_at: formatDate(updated_at),
        updated_date: updated_at,
        id: user.id,
      };
    },
    getRoleName: (state) => (role) => {
      let item = state._roles.find((i) => i.value == role);
      return item ? item.name : "";
    },
    userName: (state) => {
      let { first_name, last_name, role_id } = state.user;
      let name = "";
      if (first_name) name = `${first_name} ${last_name}`;
      let role = state._roles.find((i) => i.value == role_id);
      if (role_id && role) name = `${name} (${role.name})`;
      return name;
    },
    permissions: (state) => state._permissions,
    userTaskCount: (state) => state._userTaskCount,
  },
  mutations: {
    setPermission(state, val) {
      state._permissions = val;
    },
    setUID(state, val) {
      state.uid = val;
    },
    updateFilter(state, { key, val }) {
      state._filters[key] = val;
    },
    setUser(state, val) {
      state.user = val;
    },
  },
  actions: {
    resetUser({ state }) {
      state.user = {};
      state._userTaskCount = 0;
      state.uid = null;
      state.users = [];
      state._roles = [];
      if (unsubscribeUser) unsubscribeUser();
      if (unsubscribeRoles) unsubscribeRoles();
      if (unsubscribeUsers) unsubscribeUsers();
      if (unsubscribeClientStatus) unsubscribeClientStatus();
      if (unsubscribePaymentMethods) unsubscribePaymentMethods();
      if (unsubscribeRole) unsubscribeRole();
      if (unsubscribeClientPaymentMethods) unsubscribeClientPaymentMethods();
    },
    listenUser({ state }) {
      unsubscribeUser = onSnapshot(doc(colUsers, state.uid), (snap) => {
        state.user = getSnapDoc(snap);
        if (state.user.role_id) {
          unsubscribeRole = onSnapshot(
            doc(colRoles, state.user.role_id),
            (roleSnap) => {
              state._permissions = getSnapDoc(roleSnap).permissions;
            }
          );
        }
      });
    },
    listenCommon({ getters, state }) {
      unsubscribeUsers = onSnapshot(colUsers, (users) => {
        state.users = users.docs.map((i) => getters.mapUser(i));
      });
      unsubscribeClientStatus = onSnapshot(
        colClientStatusTypes,
        (snap) => {
          state._clientStatusTypes = mapDocs(snap.docs).map((i) => ({
            ...i,
            value: i.name_lw,
          }));
        },
        (e) => {
          console.error("Error while fetching the client status", e);
        }
      );
      unsubscribePaymentMethods = onSnapshot(colPaymentMethods, (snap) => {
        state._paymentMethods = mapDocs(snap.docs).map((i) => ({
          ...i,
          value: i.name,
        }));
      });
      unsubscribeClientPaymentMethods = onSnapshot(
        colClientPaymentMethods,
        (snap) => {
          state._clientPaymentMethods = mapDocs(snap.docs).map((i) => ({
            ...i,
            value: i.name,
            type: "mpm",
          }));
          console.log(state._clientPaymentMethods);
        }
      );
      unsubscribeRoles = onSnapshot(
        query(colRoles, orderBy("name", "asc")),
        (snap) => {
          state._roles = mapDocs(snap.docs).map((i) => ({
            ...i,
            value: i.id,
          }));
        }
      );
    },
    listenAuth({ state, dispatch }) {
      onAuthStateChanged(auth, (user) => {
        state.uid = user?.uid;
        if (user) {
          dispatch("listenUser");
          dispatch("listenCommon");
          dispatch("listenAffiliates");
          dispatch("fetchUserTaskCount");
        } else {
          dispatch("resetUser");
          dispatch("resetAffiliate");
        }
      });
    },
    async fetchUserTaskCount({ state }) {
      state._userTaskCount = 0;
      let uid = auth.currentUser?.uid;
      try {
        if (uid) {
          let countQuery = query(
            colTeamSchedules,
            where("type", "==", "task"),
            where("member", "==", uid),
            where("is_completed", "==", false)
          );
          let count = (await getCountFromServer(countQuery)).data().count;
          state._userTaskCount = count;
        }
      } catch (error) {
        console.error(
          "Error while fetching the user task count",
          error.message
        );
      }
    },
  },
};
