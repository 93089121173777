<template>
  <v-dialog v-model="dialog" persistent max-width="1000px">
    <template v-slot:activator="{ on }">
      <v-btn text color="primary" v-on="on" class="mt-3 justify-start"
        >Document Storage</v-btn
      >
    </template>
    <dialog-card
      :isSubmit="false"
      :loading="loading"
      @close="dialog = false"
      :title="`Document Storage (${currentClient.name})`"
    >
      <v-card
        v-if="hasEdit('customers')"
        flat
        class="pa-5 mb-3"
        :class="isDragged ? 'green lighten-3' : 'grey lighten-3'"
        @dragover="dragover"
        @dragleave="dragleave"
        @drop="drop"
      >
        <input
          type="file"
          multiple
          name="fields[assetsFieldHandle][]"
          id="assetsFieldHandle"
          class="w-px h-px opacity-0 overflow-hidden absolute d-none"
          @change="onFileChange"
          ref="file"
          accept=".pdf,.jpg,.jpeg,.png"
        />

        <label for="assetsFieldHandle" class="block cursor-pointer">
          <div class="d-flex align-center flex-column">
            <div style="width: 60px; height: 60px" class="text-center">
              <v-progress-circular
                indeterminate
                color="grey"
                size="40"
                v-if="loading"
              ></v-progress-circular>

              <v-icon v-else size="60px"> mdi-cloud-upload </v-icon>
            </div>
            <div class="text-center">Drag & Drop or Browse</div>
          </div>
        </label>
      </v-card>

      <static-table-view :headers="headers" :is-top="false" :list="list">
        <template v-slot:item.uploaded_at="{ item }">
          {{ formatUploadAt(item) }}
        </template>
        <template v-slot:item.action="{ item }">
          <v-menu :disabled="loading">
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" icon x-small>
                <v-icon> mdi-chevron-down </v-icon>
              </v-btn>
            </template>
            <v-list class="my-0 py-0" dense small>
              <v-list-item @click="downloadFile(item)">
                <v-list-item-title>Download File</v-list-item-title>
              </v-list-item>
              <v-divider></v-divider>
              <client-document-preview :url="item.url">
                <v-list-item>
                  <v-list-item-title>Preview File</v-list-item-title>
                </v-list-item>
              </client-document-preview>

              <template v-if="hasDelete('customers')">
                <v-divider></v-divider>
                <v-list-item @click="deleteFile(item)">
                  <v-list-item-title>Delete</v-list-item-title>
                </v-list-item>
              </template>
              <template v-if="hasEdit('customers')">
                <v-divider></v-divider>
                <rename-document-storage :item="item" />
              </template>
            </v-list>
          </v-menu>
        </template>
      </static-table-view>
    </dialog-card>
  </v-dialog>
</template>

<script>
import DialogCard from "@/components/ui/DialogCard.vue";
import StaticTableView from "@/components/ui/StaticTableView.vue";
import {
  formatDate,
  getDate,
  getHeaders,
  isDLFile,
} from "@/assets/utils/common.utils";
import {
  deleteObject,
  getDownloadURL,
  ref,
  uploadBytes,
} from "firebase/storage";
import { colClients, storage } from "@/assets/utils/firebase.utils";
import { arrayUnion, doc, updateDoc } from "firebase/firestore";
import RenameDocumentStorage from "./RenameDocumentStorage.vue";
import axios from "axios";
import ClientDocumentPreview from "./ClientDocumentPreview.vue";
export default {
  components: {
    DialogCard,
    StaticTableView,
    RenameDocumentStorage,
    ClientDocumentPreview,
  },
  data() {
    return {
      dialog: false,
      loading: false,
      isDragged: false,
    };
  },
  computed: {
    headers() {
      let doc = {
        name: "File Name",
        uploaded_at: "Upload Date",
        action: "Action",
      };
      return getHeaders(doc).map((i) => ({
        ...i,
        width: i.value == "action" ? "100px" : "",
      }));
    },
    list() {
      return (this.currentClient.file_uploads || []).filter((i) =>
        this.isVisible(i)
      );
    },
  },
  methods: {
    dragover(event) {
      event.preventDefault();
      if (this.loading) return;
      this.isDragged = true;
    },
    dragleave() {
      if (this.loading) return;
      this.isDragged = false;
    },
    drop(event) {
      event.preventDefault();
      if (this.loading) return;
      this.$refs.file.files = event.dataTransfer.files;
      this.onFileChange();

      this.isDragged = false;
    },
    isVisible({ uid, name }) {
      return this.isSendFaxAdmin || uid == this.uid || isDLFile({ name });
    },
    previewFile({ url }) {
      window.open(url, "_blank");
    },
    async downloadFile(item) {
      try {
        this.$loader.show();
        let res = await axios.get(item.url, {
          responseType: "blob",
        });
        const blobUrl = URL.createObjectURL(res.data);
        const anchor = document.createElement("a");
        let fileName = item.name;
        anchor.href = blobUrl;
        anchor.download = fileName;
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
        URL.revokeObjectURL(blobUrl);
        this.$loader.hide();
      } catch (error) {
        this.handleError(error);
      }
    },
    formatUploadAt({ uploaded_at, fileId }) {
      if (uploaded_at) return formatDate(uploaded_at);
      return formatDate(fileId);
    },
    deleteFile({ fileId, name }) {
      this.$confirm.show({
        message: `Are you sure, Do you want to remove "${name}"?`,
        onConfirm: async () => {
          let customerId = this.$route.params.id;
          let storageRef = ref(
            storage,
            `customers/${customerId}/efax/${fileId}`
          );
          let file_uploads = this.currentClient.file_uploads.filter(
            (i) => i.fileId != fileId
          );
          await deleteObject(storageRef);
          updateDoc(doc(colClients, customerId), {
            file_uploads,
          });
        },
      });
    },
    async onFileChange() {
      if (this.loading) return;
      try {
        this.loading = true;
        for (let file of Array.from(this.$refs.file.files)) {
          const parts = file.name.split(".");
          let customerId = this.$route.params.id;
          let ext = parts[parts.length - 1];
          let fileId = `${new Date().getTime()}.${ext}`;
          let storageRef = ref(
            storage,
            `customers/${customerId}/efax/${fileId}`
          );
          await uploadBytes(storageRef, file);
          let url = await getDownloadURL(storageRef);
          let data = {
            name: file.name,
            fileId,
            type: file.type,
            uid: this.uid,
            url,
            uploaded_at: getDate(),
          };
          updateDoc(doc(colClients, customerId), {
            file_uploads: arrayUnion(data),
            updated_at: getDate(),
            updated_by: this.userEmail,
          });
        }

        this.notifySuccess("Document uploaded successfully.");
        this.loading = false;
      } catch (error) {
        this.handleError(error);
      }
      this.$refs.file.value = null;
    },
  },
};
</script>

<style></style>
