<template>
  <div>
    <v-card flat class="mt-10">
      <v-data-table
        :headers="headers"
        ref="table"
        class="credit-report-list"
        :items="sortedItems"
        @click:row="onRowClick"
        :loading="loading"
        hide-default-header
        :items-per-page="-1"
        hide-default-footer
      >
        <template v-slot:header>
          <thead class="v-data-table-header">
            <tr>
              <th class="sortable" @click="sort('name')">
                Creditor/Furnisher
                <v-icon small v-if="sortBy === 'name'">{{
                  sortDesc ? "mdi-arrow-down" : "mdi-arrow-up"
                }}</v-icon>
              </th>
              <th class="sortable" @click="sort('account')">
                Account#
                <v-icon small v-if="sortBy === 'account'">{{
                  sortDesc ? "mdi-arrow-down" : "mdi-arrow-up"
                }}</v-icon>
              </th>
              <th
                class="text-center"
                style="width: 160px"
                @click="sort('equifax')"
              >
                <equifax-img />
                <v-icon small v-if="sortBy === 'equifax'">{{
                  sortDesc ? "mdi-arrow-down" : "mdi-arrow-up"
                }}</v-icon>
              </th>
              <th
                class="text-center"
                style="width: 160px"
                @click="sort('experian')"
              >
                <experian-img :width="80" />
                <v-icon small v-if="sortBy === 'experian'">{{
                  sortDesc ? "mdi-arrow-down" : "mdi-arrow-up"
                }}</v-icon>
              </th>
              <th
                class="text-center"
                style="width: 160px"
                @click="sort('transunion')"
              >
                <transunion-img :width="80" />
                <v-icon small v-if="sortBy === 'transunion'">{{
                  sortDesc ? "mdi-arrow-down" : "mdi-arrow-up"
                }}</v-icon>
              </th>
              <th style="width: 90px"></th>
            </tr>
            <tr>
              <td class="py-5 font-weight-medium">Credit Scores</td>
              <td class="py-5"></td>
              <td class="py-5 font-weight-medium text-center">
                {{ report.credit_scores.equifax }}
              </td>
              <td class="py-5 font-weight-medium text-center">
                {{ report.credit_scores.experian }}
              </td>
              <td class="py-5 font-weight-medium text-center">
                {{ report.credit_scores.transunion }}
              </td>
              <td class="py-5" v-if="hasEdit('customers')"></td>
            </tr>
          </thead>
        </template>
        <!-- <template v-slot:header.equifax="{}">
        </template>
        <template v-slot:header.experian="{}">
        </template>
        <template v-slot:header.transunion="{}">
          <transunion-img :width="80" />
        </template> -->
        <template v-slot:body.prepend> </template>

        <template v-slot:item.name="{ value, index }">
          <b>{{ index + 1 }}. </b> {{ value }}
        </template>
        <template v-slot:item.account="{ value }">
          <span v-html="value"></span>
        </template>
        <template v-slot:item.equifax="{ value }">
          <credit-report-status :status="value"></credit-report-status>
        </template>
        <template v-slot:item.transunion="{ value }">
          <credit-report-status :status="value"></credit-report-status>
        </template>
        <template v-slot:item.experian="{ value }">
          <credit-report-status :status="value"></credit-report-status>
        </template>
        <template v-slot:item.action="{ index }">
          <v-menu
            offset-y
            v-model="menus[index]"
            :close-on-click="true"
            :close-on-content-click="true"
          >
            <template v-slot:activator="{ on }">
              <v-btn icon v-on="on">
                <v-icon> mdi-chevron-down </v-icon>
              </v-btn>
            </template>
            <v-list class="pa-0" dense>
              <credit-report-item-edit
                :report="report"
                :reportAccounts="items"
                @onReportUpdate="$emit('onReportUpdate')"
                @close="menus[index] = false"
                :report-index="index"
              />
              <template v-if="hasDelete('customers')">
                <v-divider></v-divider>
                <v-list-item @click="removeReportItem(index)"
                  >Delete</v-list-item
                >
              </template>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>
    <credit-report-item-edit
      :is-preview="true"
      ref="reportView"
      :report="report"
    />
  </div>
</template>

<script>
import { formatDateOnly, getDate } from "@/assets/utils/common.utils";
import { doc, updateDoc } from "firebase/firestore";
import { colCreditReports } from "@/assets/utils/firebase.utils";
import ExperianImg from "../ExperianImg.vue";
import EquifaxImg from "../EquifaxImg.vue";
import TransunionImg from "../TransunionImg.vue";
import CreditReportStatus from "./CreditReportStatus.vue";
import CreditReportItemEdit from "./CreditReportItemEdit.vue";
export default {
  components: {
    EquifaxImg,
    TransunionImg,
    CreditReportStatus,
    ExperianImg,
    CreditReportItemEdit,
  },
  props: {
    report: {
      type: Object,
      default: () => {},
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      sortBy: null,
      sortDesc: false,
      menus: {},
    };
  },
  computed: {
    sortedItems() {
      if (!this.sortBy) return this.items;

      return [...this.items].sort((a, b) => {
        let result = 0;
        if (a[this.sortBy] < b[this.sortBy]) result = -1;
        if (a[this.sortBy] > b[this.sortBy]) result = 1;

        return this.sortDesc ? -result : result;
      });
    },
    items() {
      let { getAccountNumber } = this;
      let list = [...this.report.accounts]
        .map((i) => {
          let equifax = i.list.find((a) => a.bureau == "Equifax") || {};
          let transunion = i.list.find((a) => a.bureau == "TransUnion") || {};
          let experian = i.list.find((a) => a.bureau == "Experian") || {};
          let unspecified = i.list.filter((i) => i.status == "Unspecified");
          let account = `
        Equifax: ${getAccountNumber(equifax, i.type)}<br/>
        Experian: ${getAccountNumber(experian, i.type)}<br/>
        Transunion: ${getAccountNumber(transunion, i.type)}<br/>
        `;
          return {
            ...i,
            account,
            equifax: equifax.status || "",
            experian: experian.status || "",
            transunion: transunion.status || "",
            unspecified: unspecified.length,
          };
        })
        .sort((a, b) => b.unspecified - a.unspecified);
      let inquiryItems = this.sortEnquiry(list);
      let nonInquiryItems = this.sortTradeline(list);
      return [...inquiryItems, ...nonInquiryItems];
    },
    headers() {
      let doc = {
        name: "Creditor/Furnisher",
        account: "Account #",
        equifax: "equifax",
        experian: "experian",
        transunion: "transunion",
        action: "",
      };
      if (!this.hasEdit("customers")) {
        delete doc.action;
      }
      let leftAlgin = ["name", "account"];
      return Object.keys(doc).map((value) => ({
        value,
        cellClass: "py-3",
        text: doc[value],
        align: leftAlgin.indexOf(value) == -1 ? "center" : "left",
        width: value == "action" ? "50px" : "",
      }));
    },
  },
  methods: {
    sortEnquiry(arr) {
      let accounts = ["transunion", "equifax", "experian"];
      arr = arr.filter((i) => i.type == "inquiry");
      arr = arr.map((i) => {
        let unknown = accounts.filter((a) => !i[a]);
        let unspecified_per = accounts.filter((a) => i[a] == "Unspecified");
        let disputed_per = accounts.filter((a) => i[a] == "In Dispute");
        unspecified_per = unspecified_per.length / (3 - unknown.length);
        disputed_per = disputed_per.length / (3 - unknown.length);
        if (unspecified_per > 0) {
          i.order_per = unspecified_per;
          i.order = "unspecified";
        } else if (disputed_per > 0) {
          i.order_per = disputed_per;
          i.order = "disputed";
        } else {
          i.order_per = 1;
          i.order = "deleted";
        }
        return i;
      });
      const order = { unspecified: 0, disputed: 1, deleted: 2 };
      arr = arr.sort((a, b) => b.order_per - a.order_per);
      return arr.sort((a, b) => order[a.order] - order[b.order]);
    },
    sortTradeline(arr) {
      let accounts = ["transunion", "equifax", "experian"];
      arr = arr.filter((i) => i.type != "inquiry");
      arr = arr.map((i) => {
        let unknown = accounts.filter((a) => !i[a]);
        let disputed_per = accounts.filter((a) => i[a] == "In Dispute");
        let deleted_per = accounts.filter((a) => i[a] == "Deleted");
        let negative_per = accounts.filter((a) => i[a] == "Negative");
        deleted_per = deleted_per.length / (3 - unknown.length);
        disputed_per = disputed_per.length / (3 - unknown.length);
        negative_per = negative_per.length / (3 - unknown.length);
        if (deleted_per > 0) {
          i.order_per = deleted_per;
          i.order = "deleted";
        } else if (disputed_per > 0) {
          i.order_per = disputed_per;
          i.order = "disputed";
        } else if (negative_per > 0) {
          i.order_per = negative_per;
          i.order = "negative";
        } else {
          i.order_per = 1;
          i.order = "positive";
        }

        return i;
      });
      const order = { deleted: 0, disputed: 1, negative: 2, positive: 3 };
      arr = arr.sort((a, b) => b.order_per - a.order_per);
      return arr.sort((a, b) => order[a.order] - order[b.order]);
    },
    sort(field) {
      if (this.sortBy === field) {
        this.sortDesc = !this.sortDesc; // Toggle sort order if same field
      } else {
        this.sortBy = field; // Set the field to be sorted
        this.sortDesc = false; // Default to ascending order
      }
    },
    removeReportItem(index) {
      const vm = this;
      vm.$confirm.show({
        message: "Are you sure, Do you want to remove this?",
        onConfirm: async () => {
          try {
            let accounts = [...vm.items];
            accounts.splice(index, 1);
            await updateDoc(doc(colCreditReports, vm.report.id), {
              accounts,
              updated_at: getDate(),
            });
            vm.$emit("onReportUpdate");
          } catch (error) {
            vm.notifyError(error.message);
          }
        },
      });
    },
    getAccountNumber({ account_number, date_filed }, type) {
      if (type == "inquiry") {
        if (date_filed) return formatDateOnly(date_filed);
        return "";
      }
      return account_number || "";
    },
    onRowClick(item) {
      this.$refs.reportView.initPreview(item);
    },
    onScroll() {
      let table = this.$refs.table;
      if (table) {
        let el = this.$refs.table.$el.querySelector("table");
        let prepend = this.$refs.table.$el.querySelector("prepend");
        let thead = this.$refs.table.$el.querySelector("thead");
        var rect = el.getBoundingClientRect();
        // var scrollPosition =
        //   document.documentElement.scrollTop || document.body.scrollTop;
        if (rect.top < thead.offsetHeight) {
          thead.style.width = rect.width + "px";
          if (el) el.classList.add("thead--is-fixed");
          if (prepend) prepend.classList.add("thead--is-fixed");
        } else {
          if (el) el.classList.remove("thead--is-fixed");
          if (prepend) prepend.classList.remove("thead--is-fixed");
        }
      }
    },
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll);
  },
  mounted() {
    window.addEventListener("scroll", this.onScroll);
  },
};
</script>

<style lang="scss">
.credit-report-list {
  border-collapse: collapse;
  position: relative;
  width: 100%;
  td,
  th {
    line-height: 25px;
    border-right: 0px !important;
    &:nth-child(1) {
      width: 200px;
    }

    &:nth-child(5) {
      border-right: 0px !important;
    }
    &:nth-child(3),
    &:nth-child(4),
    &:nth-child(5) {
      width: 160px;
    }
    &:nth-child(6) {
      width: 90px;
    }
  }

  .thead {
    position: relative;
    top: 0;
  }
  .thead--is-fixed {
    thead {
      display: table;
      position: fixed;
      top: 180px;
      z-index: 200;
    }
  }
}
</style>
