<template>
  <layout :title="title" :loading="isLoading">
    <form-wrapper @submit="submit()">
      <v-row>
        <v-col cols="12" md="4" class="pb-0">
          <input-field
            v-model="name"
            label="Role Name"
            rules="required"
          ></input-field>
        </v-col>
        <v-col cols="12" md="8" class="pb-0" />
        <v-col
          cols="12"
          md="3"
          v-for="(role, i) in rolesConstant"
          :key="i"
          class="mb-5"
        >
          <label class="body-medium--bold">{{ role.title }}</label>
          <div class="mt-2">
            <v-checkbox
              hide-details
              dense
              class="mb-2"
              color="accent"
              v-for="(per, i) in role.roles"
              @change="onChange(role.name, per, $event)"
              :disabled="isDisabled(permissions[role.name], per)"
              v-model="permissions[role.name][per]"
              :key="`${role.name}-${i}`"
            >
              <span slot="label" class="body-small black--text">
                {{ formatLabel(per) }}
              </span>
            </v-checkbox>
          </div>
          <select-field
            label="Wizard Type"
            v-model="permissions[role.name].wizard_type"
            :items="wizardTypes"
            v-if="role.name == 'send-faxes' && permissions[role.name].edit"
            rules="required"
          />
        </v-col>
      </v-row>
      <div class="d-flex">
        <v-spacer></v-spacer>
        <close-button
          :disabled="loading"
          :to="{ name: 'roles-permissions' }"
        ></close-button>
        <secondary-button class="ml-3" :loading="loading" type="submit"
          >Submit</secondary-button
        >
      </div>
    </form-wrapper>
  </layout>
</template>

<script>
import Layout from "@/components/ui/Layout.vue";
import rolesConstant from "@/assets/constants/roles.constant.js";
import InputField from "@/components/ui/form/InputField.vue";
import FormWrapper from "@/components/ui/form/FormWrapper.vue";
import CloseButton from "@/components/ui/buttons/CloseButton.vue";
import SecondaryButton from "@/components/ui/buttons/SecondaryButton.vue";
import {
  addDoc,
  doc,
  getDoc,
  getDocs,
  limit,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { colRoles } from "@/assets/utils/firebase.utils";
import { getDate } from "@/assets/utils/common.utils";
import SelectField from "@/components/ui/form/SelectField.vue";
export default {
  components: {
    Layout,
    InputField,
    FormWrapper,
    CloseButton,
    SecondaryButton,
    SelectField,
  },
  data() {
    return {
      rolesConstant,
      name: "",
      isLoading: false,
      loading: false,
      permissions: {},
      wizardTypes: [
        {
          name: "Tier 1 Wizard",
          value: "tier-1",
        },
        {
          name: "Tier 2 Wizard",
          value: "tier-2",
        },
      ],
    };
  },
  computed: {
    title() {
      return this.isUpdate ? "Manage Permissions" : "Add New Role";
    },
    isUpdate() {
      return this.$route.name == "update-role";
    },
  },
  methods: {
    isDisabled(obj, per) {
      return per == "view" && (obj.edit == true || obj.delete == true);
    },
    formatLabel(per) {
      let labels = {
        delete_mpm: "Delete MPM",
        ppd: "PPD",
        mr: "MR",
        mr_ppd: "MR/PPD",
        view: "View",
        edit: "Edit",
        delete: "Delete",
        payout: "Payout",
        charge: "Charge Client",
        "saved-letters": "Client Saved Letters",
        dl_status: "Status",
      };
      return labels[per] || per;
    },
    onChange(name, per, event) {
      let permissions = { ...this.permissions };
      permissions[name][per] = event;
      let item = permissions[name];
      if (item.edit == true || item.delete == true) {
        permissions[name].view = true;
      }
      this.permissions = { ...permissions };
    },
    validatePermission() {
      let hasPermission = false;
      let permissions = { ...this.permissions };
      Object.keys(permissions).forEach((key) => {
        Object.keys(permissions[key]).forEach((k) => {
          if (permissions[key][k] == true) hasPermission = true;
        });
      });
      return hasPermission;
    },
    async submit() {
      const vm = this;
      try {
        if (!vm.validatePermission()) {
          vm.notifyError("Please select at least one permission.");
          return;
        }
        vm.loading = true;
        let name = vm.name.trim();
        let name_lw = name.toLowerCase();
        let role = {
          name,
          name_lw,
          updated_by: vm.userEmail,
          permissions: vm.permissions,
          updated_at: getDate(),
        };
        let q = query(colRoles, where("name_lw", "==", name_lw), limit(1));
        let snap = (await getDocs(q)).docs;
        let roleId = vm.$route.params.id;
        if (snap.length != 0 && roleId != snap[0].id) {
          vm.loading = false;
          vm.notifyError(
            "Another role already exists with the same name. Please enter a unique role name."
          );
          return;
        }
        if (roleId) {
          await updateDoc(doc(colRoles, roleId), role);
          vm.notifySuccess("Role has been updated successfully.");
        } else {
          await addDoc(colRoles, { ...role, created_at: getDate() });
          vm.notifySuccess("Role has been created successfully.");
        }
        vm.loading = false;
        vm.$router.replace({ name: "roles-permissions" });
      } catch (error) {
        vm.handleError(error);
      }
    },
    async init() {
      const vm = this;
      try {
        if (!vm.isUpdate) return;
        vm.isLoading = true;
        let ref = doc(colRoles, vm.$route.params.id);
        let role = (await getDoc(ref)).data() || {};
        vm.name = role.name;
        let permissions = role.permissions || {};
        this.rolesConstant.forEach((i) => {
          this.permissions[i.name] = {};
          let permission = permissions[i.name] || {};
          i.roles.forEach((k) => {
            this.permissions[i.name][k] = permission[k] == true;
          });
          if (i.name == "send-faxes") {
            this.permissions[i.name].wizard_type = permission.wizard_type;
          }
        });
        vm.isLoading = false;
      } catch (error) {
        vm.handleError(error);
      }
    },
  },
  mounted() {
    this.init();
  },
  beforeMount() {
    this.rolesConstant.forEach((i) => {
      this.permissions[i.name] = {};
      i.roles.forEach((k) => {
        this.permissions[i.name][k] = false;
      });
    });
  },
};
</script>

<style></style>
