<template>
  <v-dialog
    v-model="dialog"
    max-width="600px"
    persistent
    scrollable
    v-if="hasEdit('customers')"
  >
    <template v-slot:activator="{ on }">
      <v-btn height="40px" class="mr-3" color="primary" depressed v-on="on"
        >Recurring Schedule</v-btn
      >
    </template>
    <dialog-card
      :isSubmit="isEdit"
      title="Recurring Schedule"
      @submit="handleSubmit"
      :loading="loading"
      @close="handleClose()"
    >
      <please-wait v-if="isLoading" />
      <template v-else>
        <template v-if="isEdit">
          <v-row class="mt-0">
            <v-col cols="12" class="py-0">
              <label class="body-small">Select Credit Card</label>
              <v-radio-group
                :rules="[rulesConstants.required]"
                class="mt-0"
                v-model="form.selected_card"
              >
                <v-card
                  outlined
                  v-for="(item, i) in creditCards"
                  :key="i"
                  class="mb-3"
                >
                  <v-list-item>
                    <v-list-item-action>
                      <v-radio :value="item.id" />
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ item.name }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-card>
              </v-radio-group>
            </v-col>
            <v-col cols="6" class="py-0">
              <input-field
                v-model="form.amount"
                rules="required|decimal"
                label="Amount"
              ></input-field>
            </v-col>
            <v-col cols="6" class="py-0"> </v-col>
            <v-col cols="12" class="py-0">
              <label class="body-small"> Frequency</label>
              <v-row class="mt-1">
                <v-col cols="4" class="py-0 d-flex">
                  <div class="mr-3 mt-2">EVERY</div>
                  <input-field
                    v-model="form.frequency"
                    :disabled="isUpdate"
                    :rules="'required'"
                    type="number"
                  ></input-field>
                </v-col>
                <v-col cols="8" class="py-0">
                  <select-field
                    v-model="form.frequency_unit"
                    :items="frequencyUnits"
                    @input="
                      form.specific_day = '';
                      form.is_specific_day = false;
                    "
                    required="required"
                    :disabled="isUpdate"
                    type="number"
                  ></select-field>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" class="py-0" v-if="form.frequency_unit != 'Day'">
              <v-checkbox
                v-model="form.is_specific_day"
                @change="form.specific_day = ''"
                :disabled="isUpdate"
                :label="`Run recurring at a specific day of the ${form.frequency_unit}`"
              ></v-checkbox>
            </v-col>
            <v-col cols="12" class="py-0" v-if="form.is_specific_day">
              <select-field
                v-model="form.specific_day"
                :items="days"
                :label="dayLabel"
                type="number"
                :disabled="isUpdate"
                rules="required"
              />
            </v-col>
            <v-col cols="6" class="py-0">
              <date-field
                v-model="form.start_date"
                :rules="'required'"
                label="Start Date"
              ></date-field>
            </v-col>
            <v-col cols="6" class="py-0"></v-col>
            <v-col cols="6" class="py-0">
              <select-field
                label="End"
                v-model="form.end_type"
                rules="required"
                :items="['Never', 'Date', 'Number of Payments']"
              />
            </v-col>
            <v-col cols="6" class="py-0">
              <date-field
                v-if="form.end_type == 'Date'"
                v-model="form.end_date"
                rules="required"
                label="End Date"
              ></date-field>
              <input-field
                v-if="form.end_type == 'Number of Payments'"
                v-model="form.no_of_payments"
                :rules="'required'"
                label="Number of Payments"
                type="number"
              ></input-field>
            </v-col>
          </v-row>
        </template>
        <template v-else>
          <table style="width: 100%">
            <tr v-for="(value, key) in details" :key="key">
              <td width="40%" class="pa-2 subtitle-1">
                {{ key }}
              </td>
              <td class="pa-2 subtitle-1 black--text font-weight-bold">
                {{ value }}
              </td>
            </tr>
          </table>
        </template>
      </template>
      <div slot="footer" v-if="!isEdit">
        <error-button @click="removePayment()">Delete Schedule </error-button>
        <primary-button class="ml-3" @click="openEdit()"
          >Edit Schedule</primary-button
        >
      </div>
    </dialog-card>
  </v-dialog>
</template>

<script>
import DialogCard from "@/components/ui/DialogCard.vue";
import InputField from "@/components/ui/form/InputField.vue";
import DateField from "@/components/ui/form/DateField.vue";
import SelectField from "@/components/ui/form/SelectField.vue";
import rulesConstants from "@/assets/constants/rules.constants";
import PleaseWait from "@/components/ui/PleaseWait.vue";
import { collection, getDocs, query, where } from "firebase/firestore";
import { colClients, functions } from "@/assets/utils/firebase.utils";
import { mapDocs } from "@/assets/utils/doc.utils";
import { httpsCallable } from "firebase/functions";
import moment from "moment-timezone";
import _ from "lodash";
import {
  formatDateOnly,
  formatPrice,
  initForm,
} from "@/assets/utils/common.utils";
import ErrorButton from "@/components/ui/buttons/ErrorButton.vue";
import PrimaryButton from "@/components/ui/buttons/PrimaryButton.vue";

export default {
  components: {
    DialogCard,
    InputField,
    DateField,
    SelectField,
    PleaseWait,
    ErrorButton,
    PrimaryButton,
  },
  data() {
    return {
      dialog: false,
      loading: false,
      isLoading: true,
      isEdit: true,
      title: "Add Recurring Schedule",
      rulesConstants,
      form: {
        selected_card: "",
        amount: "",
        no_of_payments: "",
        frequency: 1,
        frequency_unit: "Month",
        specific_day: "",
        is_specific_day: false,
        end_type: "Never",
        start_date: moment().format("YYYY-MM-DD"),
        end_date: "",
      },
      creditCards: [],
      frequencyUnits: ["Week", "Month", "Year"],
    };
  },
  computed: {
    details() {
      let schedule = this.currentClient.cardknox_schedule;
      if (!schedule) return {};
      let doc = {
        "Credit Card": "",
        Amount: formatPrice(schedule.amount),
        Frequency: `${schedule.frequency} Every ${schedule.frequency_unit}`,
        "Start Date": formatDateOnly(schedule.start_date),
      };
      if (schedule.end_type == "Number of Payments") {
        doc["Number of Payments"] = schedule.no_of_payments;
      } else if (schedule.end_type == "Date") {
        doc["End Date"] = formatDateOnly(schedule.end_date);
      } else {
        doc["End Date"] = "Never";
      }

      return doc;
    },
    isUpdate() {
      return (
        _.get(this.currentClient, "cardknox_schedule.schedule_id", null) != null
      );
    },
    dayLabel() {
      switch (this.form.frequency_unit) {
        case "Week":
          return "Day Of Week";
        case "Month":
          return "Day Of Month";
        case "Year":
          return "Month Of year";
        default:
          return "Day";
      }
    },
    days() {
      let days = 7;
      switch (this.form.frequency_unit) {
        case "Month":
          days = 31;
          break;
        case "Year":
          days = 12;
          break;
      }
      let list = [];
      for (let day = 0; day < days; day++) {
        list.push({
          name: `${day + 1}`,
          value: `${day + 1}`,
        });
      }
      return list;
    },
  },
  watch: {
    dialog(val) {
      if (val) {
        this.fetchPaymentMethods();
      }
    },
  },
  methods: {
    openEdit() {
      initForm(this.form, this.currentClient.cardknox_schedule);
      this.isEdit = true;
    },
    async fetchPaymentMethods() {
      try {
        this.isLoading = true;
        let col = collection(
          colClients,
          this.$route.params.id,
          "payment_methods"
        );
        let pmQuery = query(col, where("payment_method", "==", "Credit Card"));
        this.creditCards = mapDocs((await getDocs(pmQuery)).docs).map((i) => {
          return { ...i, name: i.hint, value: i.id };
        });
        this.isLoading = false;
      } catch (error) {
        this.handleError(error);
      }
    },
    removePayment() {
      this.$confirm.show({
        message: `Are you sure, Do you want to remove this?`,
        onConfirm: async () => {
          let addMethod = httpsCallable(functions, "manageClientPaymentMethod");
          await addMethod({
            type: "delete-recurring",
            customer: this.$route.params.id,
          });
          this.isEdit = true;
          this.form = {
            selected_card: "",
            amount: "",
            no_of_payments: "",
            frequency: 1,
            frequency_unit: "Month",
            specific_day: "",
            is_specific_day: false,
            end_type: "Never",
            start_date: moment().format("YYYY-MM-DD"),
            end_date: "",
          };
        },
      });
    },
    async handleSubmit() {
      try {
        if (!this.form.selected_card) {
          return this.notifyError("Please select the credit card");
        }
        this.loading = true;
        let addSchedule = httpsCallable(functions, "manageClientPaymentMethod");
        await addSchedule({
          ...this.form,
          type: "upsert-recurring",
          customer: this.$route.params.id,
        });
        this.isEdit = false;
        this.loading = false;
      } catch (error) {
        this.handleError(error);
      }
    },
    handleClose() {
      this.dialog = false;
      if (this.isUpdate) {
        this.isEdit = false;
      } else {
        this.isEdit = true;
      }
    },
  },
  mounted() {
    if (this.isUpdate) {
      this.isEdit = false;
    } else {
      this.isEdit = true;
    }
    this.fetchPaymentMethods();
  },
};
</script>
