<template>
  <v-dialog
    v-model="dialog"
    max-width="800px"
    persistent
    v-if="hasEdit('customers')"
  >
    <template v-slot:activator="{ on }">
      <v-btn height="40px" class="mr-3" depressed color="success" v-on="on"
        >Charge Client</v-btn
      >
    </template>
    <dialog-card
      :title="title"
      @submit="handleSubmit"
      @close="dialog = false"
      :loading="loading"
      ref="dialog"
    >
      <running-totals v-if="dialog" :isPage="false" />

      <v-row>
        <v-col cols="12" class="py-0 d-flex align-center">
          <div class="flex-1">
            <select-field
              v-model="formData.payment_method_id"
              :items="clientPaymentMethods"
              :rules="'required'"
              @input="onPaymentMethodChange"
              :loading="isLoading"
              label="Payment Method"
            ></select-field>
          </div>
          <div class="ml-3" style="width: 120px">
            <add-client-payment-method-dialog
              @onPaymentMethod="fetchPaymentMethods()"
            />
          </div>
        </v-col>

        <v-col cols="12" md="4" class="py-0">
          <input-field
            v-model="formData.amount"
            :rules="'required|decimal'"
            label="Amount"
            prefix="$"
          ></input-field>
        </v-col>
        <v-col cols="12" md="4" class="py-0">
          <select-field
            v-model="formData.type"
            :rules="'required'"
            :items="types"
            label="Type"
          ></select-field>
        </v-col>
        <v-col cols="12" md="4" class="py-0">
          <date-field
            v-model="formData.transaction_date"
            :rules="'required'"
            :readonly="isCreditCard"
            :max="maxDate"
            label="Transaction Date"
          />
        </v-col>
      </v-row>
    </dialog-card>
  </v-dialog>
</template>

<script>
import DialogCard from "@/components/ui/DialogCard.vue";
import SelectField from "@/components/ui/form/SelectField.vue";
import InputField from "@/components/ui/form/InputField.vue";
import { mapDocs, sortAlpha } from "@/assets/utils/doc.utils";
import { collection, getDocs, query, where } from "firebase/firestore";
import { clientPaymentTypes } from "@/assets/constants/client.constants";
import { colClients, functions } from "@/assets/utils/firebase.utils";
import DateField from "@/components/ui/form/DateField.vue";
import { httpsCallable } from "firebase/functions";
import moment from "moment-timezone";
import RunningTotals from "@/pages/mycompany/RunningTotals.vue";
import AddClientPaymentMethodDialog from "./AddClientPaymentMethodDialog.vue";

export default {
  components: {
    DialogCard,
    SelectField,
    InputField,
    DateField,
    RunningTotals,
    AddClientPaymentMethodDialog,
  },
  data() {
    return {
      dialog: false,
      loading: false,
      title: "Charge Client",
      isLoading: true,
      formData: {
        payment_method_id: "",
        amount: "",
        transaction_date: "",
        type: "",
        portalType: "",
      },
      maxDate: moment().format("YYYY-MM-DD"),
      ccPaymentMethods: [],
    };
  },
  computed: {
    clientPaymentMethods() {
      let paymentMethods = this.$store.getters.clientPaymentMethods;
      return sortAlpha([...paymentMethods, ...this.ccPaymentMethods]);
    },
    isBoth() {
      return this.currentClient.client_type == "Both";
    },
    types() {
      return ["Sale", "Refund"];
    },
    isCreditCard() {
      let paymentMethod = this.clientPaymentMethods.find(
        (i) => i.value == this.formData.payment_method_id
      );
      return paymentMethod != null && paymentMethod.type == "cc";
    },
  },
  watch: {
    dialog(val) {
      this.formData.transaction_date = "";
      this.$nextTick(() => {
        this.$refs.dialog.reset();
        if (val) {
          this.formData = {
            payment_method_id: "",
            amount: "",
            transaction_date: moment().format("YYYY-MM-DD"),
            type: "",
          };
          console.log(this.formData);
          this.fetchPaymentMethods();
        }
      });
    },
  },
  methods: {
    onPaymentMethodChange() {
      if (this.isCreditCard) {
        this.formData.transaction_date = moment().format("YYYY-MM-DD");
      }
    },
    async handleSubmit() {
      try {
        this.loading = true;
        let chargeClient = httpsCallable(
          functions,
          "insertManualPaymentAction"
        );

        await chargeClient({
          ...this.formData,
          payment_type: this.isCreditCard ? "cc" : "mpm",
          customer_id: this.$route.params.id,
        });
        this.dialog = false;
        this.loading = false;
      } catch (error) {
        this.handleError(error);
      }
    },
    async fetchPaymentMethods() {
      try {
        this.isLoading = true;
        let col = collection(
          colClients,
          this.$route.params.id,
          "payment_methods"
        );
        let listQuery = query(
          col,
          where("payment_method", "==", "Credit Card")
        );
        this.ccPaymentMethods = mapDocs((await getDocs(listQuery)).docs).map(
          (i) => {
            let paymentMethod = clientPaymentTypes.find(
              (p) => p.value == i.payment_method
            );
            if (paymentMethod) {
              i.name = `${i.payment_method}: ${i[paymentMethod.title]}`;
            }
            return { ...i, value: i.id, type: "cc" };
          }
        );
        this.isLoading = false;
      } catch (error) {
        this.handleError(error);
      }
    },
  },
};
</script>
