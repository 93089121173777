<template>
  <v-dialog v-model="dialog" max-width="1000px" scrollable>
    <template v-slot:activator="{ on }">
      <span v-on="on" class="cursor-pointer">
        <slot></slot>
      </span>
    </template>
    <dialog-card title="Preview" :isSubmit="false" @close="dialog = false">
      <template v-if="isPdf">
        <iframe
          :src="url"
          width="100%"
          height="600px"
          style="border: none"
        ></iframe>
      </template>
      <div v-else>
        <img
          :src="url"
          width="100%"
          height="600"
          contain
          class="mx-auto"
          style="object-fit: contain"
        />
      </div>
    </dialog-card>
  </v-dialog>
</template>

<script>
import DialogCard from "@/components/ui/DialogCard.vue";
export default {
  components: { DialogCard },
  props: {
    url: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      dialog: false,
    };
  },
  computed: {
    isPdf() {
      return (this.url || "").includes(".pdf");
    },
  },
};
</script>

<style></style>
