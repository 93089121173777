<template>
  <layout :title="title" :loading="isLoading">
    <form-wrapper @submit="submit()">
      <v-row class="form-row">
        <v-col cols="12" md="4">
          <input-field
            label="First Name"
            rules="required"
            v-model="form.first_name"
          ></input-field>
        </v-col>
        <v-col cols="12" md="4">
          <input-field
            label="Last Name"
            rules="required"
            v-model="form.last_name"
          ></input-field>
        </v-col>
        <v-col cols="12" md="4">
          <input-field
            label="Email"
            rules="required|email"
            v-model="form.email"
          ></input-field>
        </v-col>
        <v-col cols="12" md="4">
          <phone-field
            label="Phone (M)"
            rules="phone"
            v-model="form.phone"
          ></phone-field>
        </v-col>
        <v-col cols="12" md="4">
          <input-field
            label="Comission"
            placeholder="Commission Rate %"
            rules="required|commission"
            v-model="form.commission_rate"
          ></input-field>
        </v-col>
      </v-row>
      <div class="d-flex">
        <v-spacer></v-spacer>
        <close-button
          :disabled="loading"
          :to="{ name: 'affiliates' }"
        ></close-button>
        <secondary-button class="ml-3" :loading="loading" type="submit"
          >Submit</secondary-button
        >
      </div>
    </form-wrapper>
  </layout>
</template>

<script>
import Layout from "@/components/ui/Layout.vue";
import InputField from "@/components/ui/form/InputField.vue";
import PhoneField from "@/components/ui/form/PhoneField.vue";
import CloseButton from "@/components/ui/buttons/CloseButton.vue";
import SecondaryButton from "@/components/ui/buttons/SecondaryButton.vue";
import FormWrapper from "@/components/ui/form/FormWrapper.vue";
import { getDate, initForm } from "@/assets/utils/common.utils";
import { colAffiliates } from "@/assets/utils/firebase.utils";
import {
  addDoc,
  doc,
  getDoc,
  getDocs,
  limit,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
export default {
  components: {
    Layout,
    InputField,
    PhoneField,
    CloseButton,
    SecondaryButton,
    FormWrapper,
  },
  data() {
    return {
      loading: false,
      isLoading: false,
      form: {
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        commission_rate: "0.00",
      },
    };
  },
  computed: {
    isUpdate() {
      return this.$route.name == "update-affiliate";
    },
    title() {
      return this.isUpdate ? "Edit Affiliate" : "Add New Affiliate";
    },
  },
  methods: {
    async submit() {
      const vm = this;
      try {
        vm.loading = true;
        let affiliateId = this.$route.params.id;
        let email = vm.form.email.trim().toLowerCase();
        let formDoc = {
          ...vm.form,
          email,
          updated_at: getDate(),
          updated_by: vm.userEmail,
        };
        let q = query(colAffiliates, where("email", "==", email), limit(1));
        let snap = (await getDocs(q)).docs;
        if (snap.length != 0 && affiliateId != snap[0].id) {
          vm.loading = false;
          vm.notifyError(
            "Another affiliate already exists with the same Email, please enter a unique Email."
          );
          return;
        }
        if (vm.isUpdate) {
          let ref = doc(colAffiliates, affiliateId);
          await updateDoc(ref, formDoc);
          vm.notifySuccess("Affiliate updated successfully.");
        } else {
          formDoc.commission = 0;
          formDoc.commission_owed = 0;
          await addDoc(colAffiliates, { ...formDoc, created_at: getDate() });
          vm.notifySuccess("Affiliate added successfully.");
        }
        vm.$router.replace({ name: "affiliates" });
        vm.loading = false;
      } catch (error) {
        vm.handleError(error);
      }
    },
    async init() {
      const vm = this;
      if (!vm.isUpdate) return;
      try {
        vm.isLoading = true;
        let affiliateId = this.$route.params.id;
        let affiliate = (await getDoc(doc(colAffiliates, affiliateId))).data();
        initForm(vm.form, affiliate);
        vm.isLoading = false;
      } catch (error) {
        vm.handleError(error);
      }
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style></style>
