<template>
  <div style="height: 345px">
    <div class="title my-3 d-flex align-center">
      Documents
      <v-spacer></v-spacer>
      <customize-documents
        v-if="documents.length && hasEdit('customers') && !loading"
      />
    </div>
    <template v-if="loading">
      <v-skeleton-loader
        type="list-item-two-line, divider"
        v-for="i in 4"
        :key="i"
      />
    </template>
    <v-card height="400px" style="overflow-y: auto" flat v-else>
      <v-list class="py-0">
        <template v-for="(item, i) in documents">
          <v-list-item :key="i">
            <v-list-item-avatar>
              <v-checkbox readonly v-model="form[item.id]" />
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>
                {{ item.name }}
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <div class="d-flex">
                <template v-if="getDocument(item) != null">
                  <client-document-preview
                    v-if="hasRead('customers')"
                    :url="getDocument(item).url"
                  >
                    <v-btn small icon>
                      <v-icon>mdi-eye</v-icon>
                    </v-btn>
                  </client-document-preview>
                  <v-btn
                    small
                    icon
                    class="mx-"
                    color="primary"
                    v-if="hasRead('customers')"
                    @click="downloadFile(item)"
                  >
                    <v-icon>mdi-download-circle</v-icon>
                  </v-btn>
                  <v-btn
                    small
                    icon
                    color="red"
                    v-if="hasDelete('customers')"
                    @click="deleteDocument(item)"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </template>
                <template v-else>
                  <v-btn
                    text
                    class="font-weight-bold"
                    small
                    v-if="
                      item.id == 'utility-bill-proof-of-address' &&
                      hasEdit('customers')
                    "
                    @click="generateBill()"
                    :loading="isGenerateBillLoading"
                    color="primary"
                    >Generate</v-btn
                  >
                  <v-btn
                    small
                    text
                    color="primary"
                    v-if="hasEdit('customers')"
                    @click="openFile(item)"
                  >
                    <v-icon small>mdi-plus</v-icon>
                    Add</v-btn
                  >
                </template>
              </div>
            </v-list-item-action>
          </v-list-item>
          <v-divider :key="'divider-' + i"></v-divider>
        </template>
      </v-list>
      <input type="file" ref="file" class="d-none" @change="onFileChange" />
    </v-card>
  </div>
</template>

<script>
import {
  addDoc,
  deleteDoc,
  doc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { mapGetters } from "vuex";
import {
  colCustomerDocuments,
  functions,
  storage,
} from "@/assets/utils/firebase.utils";
import { mapDocs } from "@/assets/utils/doc.utils";
import {
  uploadBytes,
  ref,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import { getDate } from "@/assets/utils/common.utils";
import CustomizeDocuments from "./CustomizeDocuments.vue";
import { httpsCallable } from "firebase/functions";
import axios from "axios";
import { documentType } from "@/assets/constants/client.constants";
import ClientDocumentPreview from "./ClientDocumentPreview.vue";
let progressIDs = ["photo-id-copy", "social-security-card"];

export default {
  components: { CustomizeDocuments, ClientDocumentPreview },
  data() {
    return {
      customerDocuments: [],
      selectDocument: null,
      loading: true,
      isGenerateBillLoading: false,
      form: {},
    };
  },
  computed: {
    ...mapGetters(["documents"]),
  },
  methods: {
    getDocument({ id }) {
      let item = this.customerDocuments.find((i) => i.document_id == id);
      return item;
    },
    async fetchDocuments() {
      try {
        this.loading = true;
        await this.$store.dispatch("listenDocuments");
        let customerId = this.$route.params.id;
        let documentQuery = query(
          colCustomerDocuments,
          where("customer", "==", customerId)
        );
        this.customerDocuments = mapDocs((await getDocs(documentQuery)).docs);
        this.documents.forEach((i) => {
          this.form[i.id] = this.getDocument(i) != null;
        });
        this.loading = false;
      } catch (error) {
        console.log("Error while fetching the documents");
        this.handleError(error);
      }
    },
    openFile(item) {
      this.selectDocument = item;
      this.$refs.file.click();
    },
    deleteDocument(item) {
      this.$confirm.show({
        message: `Are you sure, Do you want remove "${item.name}"?`,
        onConfirm: async () => {
          try {
            let customerId = this.$route.params.id;
            let file = this.getDocument(item);
            let storageRef = ref(
              storage,
              `customers/${customerId}/documents/${file.name}`
            );
            await deleteDoc(doc(colCustomerDocuments, file.id));
            await deleteObject(storageRef);
            if (progressIDs.indexOf(item.id) != -1) {
              this.$emit("onFileUpdate");
            }
            this.notifySuccess("Document removed successfully.");
            this.fetchDocuments();
          } catch (error) {
            this.handleError(error);
          }
        },
      });
    },
    previewFile(item) {
      let file = this.getDocument(item);
      if (file) {
        window.open(file.url, "_blank");
      } else {
        this.notifyError("File not found");
      }
    },

    async onFileChange(e) {
      let file = e.target.files[0];
      if (file == null) return;
      if (
        this.selectDocument.id == documentType.DL_SSN_BINDER &&
        file.name.includes("&")
      ) {
        this.notifyError('Filename should not contain the "&" symbol.');
        this.$refs.file.value = null;
        return;
      }

      try {
        this.loading = true;
        const parts = file.name.split(".");
        let customerId = this.$route.params.id;
        let ext = parts[parts.length - 1];
        let name = `${this.selectDocument.id}.${ext}`;
        let storageRef = ref(
          storage,
          `customers/${customerId}/documents/${name}`
        );
        await uploadBytes(storageRef, file);
        let url = await getDownloadURL(storageRef);
        await addDoc(colCustomerDocuments, {
          uid: this.uid,
          customer: customerId,
          created_at: getDate(),
          file_name: file.name,
          name,
          document_id: this.selectDocument.id,
          url,
        });
        await this.fetchDocuments();
        if (progressIDs.indexOf(this.selectDocument.id) != -1) {
          this.$emit("onFileUpdate");
        }
        this.notifySuccess("Document uploaded successfully.");
      } catch (error) {
        this.handleError(error);
      }
      this.$refs.file.value = null;
    },
    async downloadFile(item) {
      let file = this.getDocument(item);
      if (file) {
        try {
          this.$loader.show();
          let res = await axios.get(file.url, {
            responseType: "blob",
          });
          const blobUrl = URL.createObjectURL(res.data);
          const anchor = document.createElement("a");
          let fileName = file.file_name || file.name;
          if (item.id == documentType.DL_SSN_BINDER) {
            fileName = (fileName || "").split("&").join("");
          }
          anchor.href = blobUrl;
          anchor.download = fileName;
          document.body.appendChild(anchor);
          anchor.click();
          document.body.removeChild(anchor);
          URL.revokeObjectURL(blobUrl);
          this.$loader.hide();
        } catch (error) {
          this.handleError(error);
        }
      }
    },
    async generateBill() {
      try {
        this.isGenerateBillLoading = true;
        let generateBill = await httpsCallable(
          functions,
          "generatePhoneBillAction"
        );
        await generateBill({ id: this.$route.params.id });
        this.notifySuccess("Bill generated successfully.");
        this.isGenerateBillLoading = false;
      } catch (error) {
        this.isGenerateBillLoading = false;
        this.handleError(error);
      }
    },
  },
  mounted() {
    this.fetchDocuments();
  },
};
</script>

<style></style>
