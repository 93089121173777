<template>
  <div>
    <form-wrapper>
      <v-row>
        <v-col cols="4" class="d-flex align-end">
          <input-field
            hide-details
            class="flex-1"
            clearable
            @input="onSearch"
            v-model="search"
            @enter="onSearch"
            :label="isAdvance ? 'Search by title' : ''"
            placeholder="Search by title"
          />
        </v-col>
        <template v-if="isAdvance">
          <v-col cols="2">
            <date-field hide-details v-model="form.from" label="Start Date" />
          </v-col>
          <v-col cols="2">
            <date-field hide-details v-model="form.to" label="End Date" />
          </v-col>
          <v-col cols="2">
            <select-field
              v-model="form.status"
              hide-details
              clearable
              :items="faxStatus"
              placeholder="All"
              label="Status"
            />
          </v-col>
          <v-col cols="2">
            <select-field
              v-model="form.schedule"
              :items="faxSchedules"
              hide-details
              clearable
              item-text="schedule_name"
              placeholder="All"
              label="Schedule"
            />
          </v-col>
          <v-col cols="4" v-if="isDisputeLetters">
            <client-autocomplete
              v-model="form.customer"
              placeholder="All"
              clearable
              hide-details
              label="Customer"
            />
          </v-col>
          <v-col cols="4">
            <autocomplete
              v-model="form.template"
              placeholder="All"
              clearable
              :items="faxTemplates"
              hide-details
              item-text="template_name"
              label="Template"
            />
          </v-col>
          <v-col cols="4" class="d-flex align-end">
            <primary-button @click="applyFilter()">Search</primary-button>
            <text-button @click="isAdvance = false">Basic Search</text-button>
            <text-button @click="resetFilter()"> Reset Filter</text-button>
          </v-col>
        </template>
      </v-row>
      <v-btn
        text
        v-if="!isAdvance"
        @click="isAdvance = true"
        class="primary--text px-0 mt-2"
        >Advanced Search</v-btn
      >
    </form-wrapper>
  </div>
</template>

<script>
import PrimaryButton from "../ui/buttons/PrimaryButton.vue";
import TextButton from "../ui/buttons/TextButton.vue";
import Autocomplete from "../ui/form/Autocomplete.vue";
import DateField from "../ui/form/DateField.vue";
import FormWrapper from "../ui/form/FormWrapper.vue";
import InputField from "../ui/form/InputField.vue";
import SelectField from "../ui/form/SelectField.vue";
import { faxStatusList } from "@/assets/constants/fax.constants.js";
import ClientAutocomplete from "../clients/ClientAutocomplete.vue";
export default {
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    headers: {
      type: Array,
      default: () => [],
    },
    items: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      importExport: "",
      isAdvance: false,
      search: "",
      form: {
        status: "",
        schedule: "",
        from: "",
        to: "",
        template: "",
        customer: "",
      },
      loading: false,
    };
  },
  inject: ["faxSchedules", "faxTemplates", "isDisputeLetters"],
  components: {
    FormWrapper,
    InputField,
    SelectField,
    DateField,
    PrimaryButton,
    TextButton,
    Autocomplete,
    ClientAutocomplete,
  },
  computed: {
    faxStatus() {
      let list = faxStatusList;
      if (!this.isSendFaxAdmin && !this.hasSendFaxStatus) {
        let skip = [
          "MAILED",
          "SENT TO 1 OF 2 RECIPIENTS",
          "SENT TO 0 OF 2 RECIPIENTS",
          "UPLOADED TU",
          "UPLOADED EFX",
          "UPLOADED EXP",
        ];
        list = list.filter((i) => skip.indexOf(i) == -1);
      }
      return list;
    },
  },
  methods: {
    resetFilter() {
      this.form = {};
      this.search = "";
      this.$emit("onFilterApply", {});
    },
    applyFilter() {
      let form = { ...this.form, search: this.search };
      this.$emit("onFilterApply", form);
    },
    onQuickFormChange() {
      this.$emit("onFilterApply", { search: this.search });
    },
    onSearch() {
      const vm = this;
      if (vm.isAdvance == true) return;
      if (!vm.search) return vm.onQuickFormChange();
      if (vm.search != null && vm.search.length < 3) return;
      vm.onQuickFormChange();
    },
  },
};
</script>

<style></style>
