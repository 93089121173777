<template>
  <v-dialog v-model="dialog" persistent scrollable max-width="1440px">
    <template v-slot:activator="{ on }">
      <v-btn color="primary" text v-on="on">View Contract History</v-btn>
    </template>
    <dialog-card
      :title="`Contract History`"
      :isSubmit="false"
      @close="dialog = false"
      :loading="isLoading"
    >
      <static-table-view
        :isTop="false"
        :loading="loading"
        :list="items"
        :headers="headers"
      >
        <template v-slot:item.status="{ value }">
          <v-chip :color="getStatusColor(value)" class="text-capitalize">
            {{ value }}</v-chip
          >
        </template>
        <template v-slot:item.action="{ item }">
          <div class="d-flex align-center">
            <add-contract @update="fetchContracts()" :item="item" />
            <client-agreement-preview-dialog :isIcon="true" :agreement="item" />
            <v-btn
              color="success"
              class="ml-3"
              @click="sendAgreement(item)"
              :loading="agreementId == item.id && isLoading"
              v-if="hasEdit('client-agreements') && item.status == 'draft'"
              depressed
            >
              Send</v-btn
            >
            <delete-agreement :item="item" @onDelete="fetchContracts()" />
          </div>
        </template>
      </static-table-view>
    </dialog-card>
  </v-dialog>
</template>

<script>
import StaticTableView from "@/components/ui/StaticTableView.vue";
import DialogCard from "@/components/ui/DialogCard.vue";
import { getDocs, query, where, orderBy } from "firebase/firestore";
import { colClientAgreements, functions } from "@/assets/utils/firebase.utils";
import { mapDocs } from "@/assets/utils/doc.utils";
import ClientAgreementPreviewDialog from "../ClientAgreementPreviewDialog.vue";
import { httpsCallable } from "firebase/functions";
import DeleteAgreement from "@/components/mycompany/DeleteAgreement.vue";
import AddContract from "./AddContract.vue";
import { formatDate } from "@/assets/utils/common.utils";
import moment from "moment-timezone";
export default {
  components: {
    StaticTableView,
    DialogCard,
    ClientAgreementPreviewDialog,
    DeleteAgreement,
    AddContract,
  },
  data() {
    return {
      dialog: false,
      items: [],
      headers: [
        {
          text: "Name",
          value: "customer_name",
        },
        {
          text: "Status",
          value: "status",
          width: "150px",
        },
        {
          text: "Created At",
          value: "created_at",
          width: "200px",
        },
        {
          text: "Actions",
          value: "action",
          width: "100px",
        },
      ],
      loading: false,
      agreementId: "",
      isLoading: false,
    };
  },
  watch: {
    dialog(val) {
      if (val) {
        this.fetchContracts();
      }
    },
  },
  methods: {
    getStatusColor(value) {
      switch (value) {
        case "signed":
          return "success";
        case "sent":
          return "warning";
        default:
          return "lightgrey";
      }
    },
    async fetchContracts() {
      try {
        this.items = [];
        this.loading = true;
        let listQuery = query(
          colClientAgreements,
          orderBy("created_at", "desc"),
          where("customer_id", "==", this.$route.params.id)
        );
        this.items = mapDocs((await getDocs(listQuery)).docs).map((i) => {
          return {
            ...i,
            timestamp: formatDate(i.agreement_signed_on),
            signedAt: moment(i.agreement_signed_on).format("MMM DD, YYYY"),
          };
        });
        console.log(this.items);
        this.loading = false;
      } catch (error) {
        this.handleError(error);
      }
    },
    async sendAgreement({ id }) {
      try {
        this.agreementId = id;
        this.isLoading = true;
        let sendContract = httpsCallable(functions, "manageAgreement");
        await sendContract({
          id,
          type: "send-agreement",
        });
        this.fetchContracts();
        this.isLoading = false;
      } catch (error) {
        this.handleError(error);
      }
    },
  },
};
</script>

<style></style>
