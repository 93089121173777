/* eslint-disable no-useless-escape */
import Vue from "vue";
import { ValidationProvider, extend } from "vee-validate";

const discoverRegex =
  /^(6011|65|64[4-9]|62212[6-9]|6221[3-9]|622[2-8]|6229[01]|62292[0-5])\d{12}$/;

const emailRegex =
  /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
const cvvRegex = /^[0-9]{3,4}$/;

extend("requiredBoolean", {
  validate(value) {
    return {
      required: true,
      valid: value !== "",
    };
  },
  message: "Field is required",
  computesRequired: true,
});
extend("required", {
  validate(value) {
    return {
      required: true,
      valid: ["", null, undefined, []].indexOf(value) === -1,
    };
  },
  message: "Field is required",
  computesRequired: true,
});
extend("schedule", {
  validate(value) {
    return {
      required: true,
      valid: value !== null,
    };
  },
  message: "Field is required",
  computesRequired: true,
});
extend("select", {
  validate(value) {
    return {
      required: true,
      valid: typeof value === "object" && value.length != 0,
    };
  },
  message: "Field is required",
  computesRequired: true,
});
extend("email", {
  validate(value) {
    return {
      required: true,
      valid: emailRegex.test(value),
    };
  },
  message: "E-mail must be valid",
  computesRequired: true,
});
extend("phone", {
  validate(value) {
    return {
      required: true,
      valid: value && value.length == 14,
    };
  },
  message: "Please enter a valid US Phone number",
  computesRequired: true,
});
extend("cc", {
  validate(value) {
    var valid = require("card-validator");
    return {
      required: true,
      valid: value && valid.number(value).isValid,
    };
  },
  message: "Please enter a valid card number",
  computesRequired: true,
});
extend("ssn", {
  validate(value) {
    return {
      required: true,
      valid: value && value.length == 11,
    };
  },
  message: "Please enter a valid SSN number",
  computesRequired: true,
});
extend("zip", {
  validate(value) {
    return {
      required: true,
      valid: value && value.length == 5,
    };
  },
  message: "Please enter a valid zip code",
  computesRequired: true,
});
extend("cvv", {
  validate(value) {
    return {
      required: true,
      valid: cvvRegex.test(value),
    };
  },
  message: "Please enter a valid cvv",
  computesRequired: true,
});
extend("commission_rate", {
  validate(v) {
    return {
      required: true,
      valid: v <= 100 && v >= 0,
    };
  },
  message: "Percentage should be between 0 to 100",
  computesRequired: true,
});
extend("commission", {
  validate(v) {
    return {
      required: true,
      valid: v < 1,
    };
  },
  message: "Commission rate must be less then 100%",
  computesRequired: true,
});

extend("restrictDiscover", {
  validate(v) {
    return {
      required: true,
      valid: v && !discoverRegex.test(v.split(" ").join("")),
    };
  },
  message: "Card type can't be Discover",
  computesRequired: true,
});
extend("decimal", {
  validate(value) {
    return /^-?\d+(\.\d+)?$/.test(value);
  },
  message: "Only numeric values are allowed",
  computesRequired: true,
});

Vue.component("ValidationProvider", ValidationProvider);
