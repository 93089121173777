<template>
  <div :class="readonly ? 'editor-readonly' : ''">
    <div class="d-flex mb-2">
      <label class="mr-1">{{ label }}</label>
      <v-spacer></v-spacer>
      <template v-if="!readonly"> Type @ to insert placehoders </template>
    </div>
    <textarea
      v-model="input"
      :disabled="readonly"
      class="template-editor"
    ></textarea>
    <v-text-field
      class="hide-input"
      v-model="input"
      :rules="rules"
    ></v-text-field>
  </div>
</template>

<script>
import rulesConstants from "@/assets/constants/rules.constants.js";
export default {
  props: {
    label: {
      type: String,
      default: "Template Body",
    },
    value: {
      type: String,
      default: "",
    },
    minHeight: {
      type: String,
      default: "100px",
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    fieldItems: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    // Editor,
  },
  data() {
    return {
      api: "d57puqtf9th4vc6gppyd0qz1xsyhgjvbc71q13nibwea9t6x",
      input: "",
      rules: [rulesConstants.required],
    };
  },
  watch: {
    value(val) {
      if (val != this.input) {
        this.input = val;
        // eslint-disable-next-line no-undef
        if (tinymce.activeEditor) {
          // eslint-disable-next-line no-undef
          tinymce.activeEditor.setContent(val);
        }
      }
    },
  },
  computed: {
    customerFields() {
      let doc = {
        name: "CLIENT NAME",
        street: "CLIENT ADDRESS",
        city: "CLIENT CITY",
        state: "CLIENT STATE",
        postal_code: "CLIENT POSTCODE",
        birth_date: "CLIENT DATE OF BIRTH",
        phone: "CLIENT PHONE",
        fax: "CLIENT Fax",
        today: "TODAY'S DATE",
        // company_name: "COMPANY NAME",
        // // company_logo: "COMPANY LOGO",
        // company_address: "COMPANY ADDRESS",
        // company_city: "COMPANY CITY",
        // company_state: "COMPANY STATE",
        // company_postcode: "COMPANY POSTCODE",
        // company_phone: "COMPANY PHONE",
        // company_fax: "COMPANY FAX",
      };
      return Object.keys(doc).map((value) => ({ value, name: doc[value] }));
    },

    fields() {
      let fields = [...this.customerFields].map((item) => {
        return { text: item.name, ...item };
      });
      return [...fields, ...(this.fieldItems || [])];
    },
    options() {
      return {
        height: this.minHeight,
        statusbar: false,
        menubar: true,
        newline_behavior: "linebreak",
        content_style: `.tag-item, .tag-item:visited {font-weight: 400;padding: 0;-webkit-border-radius: 2px;-moz-border-radius: 2px;border-radius: 2px;cursor: default;}.tag-item:hover {text-decoration: none;}.tag-item {color: white;font-weight: 400;font-size: 14px;border-radius: 5px;background: #3949ab;padding: 2px 10px;}.clear-btn {background: white;border-radius: 2px;padding-left: 5px;padding-right: 5px;margin-left: 5px;border-radius: 10px;font-weight: 400;color: #181818;} p {margin:0px!important}`,
        plugins: [
          "upload-images",
          "advlist",
          "autolink",
          "lists",
          "link",
          "image",
          "charmap",
          "preview",
          "anchor",
          "searchreplace",
          "visualblocks",
          "code",
          "fullscreen",
          "insertdatetime",
          "media",
          "table",
          "help",
          "wordcount",
        ],
        toolbar:
          "undo redo | blocks | " +
          "bold italic backcolor | alignleft aligncenter " +
          "alignright alignjustify | bullist numlist outdent indent | " +
          "removeformat",
        setup: (editorItem) => {
          const vm = this;
          const emitContent = () => {
            setTimeout(() => {
              const content = editorItem.getContent();
              vm.input = content;
              vm.$emit("input", content);
            }, 0);
          };

          editorItem.on("Change", emitContent);
          editorItem.on("Input", emitContent);
          editorItem.on("ExecCommand", emitContent);
          editorItem.on("NodeChange", emitContent);
          window.tinymce.PluginManager.add("upload-images", (editor) => {
            const getMatchedChars = (pattern) => {
              return vm.fields.filter(
                (char) =>
                  char.text.toLowerCase().indexOf(pattern.toLowerCase()) !== -1
              );
            };
            const onAction = (autocompleteApi, rng, value) => {
              editor.selection.setRng(rng);
              let hit = vm.fields.find((item) => item.value == value);
              editor.insertContent(
                `<label contentEditable="false" spellcheck="false" class="tag-item" data-id="${hit.value}">${hit.text}</label> &nbsp;`
              );
              autocompleteApi.hide();
              vm.input = editor.getContent();
              vm.$emit("input", vm.input);
            };

            editor.ui.registry.addAutocompleter("specialchars", {
              ch: "@",
              minChars: 0,
              columns: 1,
              onAction: onAction,
              fetch: (pattern) => {
                return new Promise((resolve) => {
                  const results = getMatchedChars(pattern).map((char) => ({
                    type: "cardmenuitem",
                    value: char.value,
                    label: char.text,
                    items: [
                      {
                        type: "cardcontainer",
                        direction: "vertical",
                        items: [
                          {
                            type: "cardtext",
                            text: char.text,
                            name: "char_name",
                          },
                        ],
                      },
                    ],
                  }));
                  resolve(results);
                });
              },
            });
          });
        },
      };
    },
  },
  methods: {
    onImageSelect(url) {
      this.editor.execCommand("InsertImage", true, url);
    },
  },
  mounted() {
    const vm = this;
    vm.input = vm.value || "";
    vm.$nextTick(() => {
      // eslint-disable-next-line no-undef
      tinymce.init({
        ...{
          selector: ".template-editor",
        },
        ...vm.options,
      });
    });
  },
};
</script>

<style lang="scss">
.tox-tinymce {
  border-radius: 4px !important;
}
.editor-readonly .tox .tox-edit-area__iframe {
  background: #cccccc17 !important;
}
.hide-input {
  padding: 0px !important;
  margin: 0px !important;
  .v-input__slot {
    display: none;
  }
  .v-text-field__details {
    margin-top: 8px;
  }
}
.tox-editor-header {
  padding: 0px !important;
}
</style>
