<template>
  <div>
    <div class="title">Dispute Status</div>
    <v-row class="mt-0">
      <v-col cols="12" xl="8">
        <v-card outlined flat class="overflow-hidden">
          <v-data-table
            :headers="headers"
            :loading="loading"
            hide-default-footer
            :items="items"
            :items-per-page="-1"
          >
            <template v-slot:item.status="{ value }">
              <div class="d-flex align-center">
                <div
                  style="width: 15px; height: 15px"
                  :class="getStatusClass(value)"
                  class="mr-2"
                ></div>
                {{ value }}
              </div>
            </template>
            <template v-slot:header.Equifax="{}">
              <equifax-img :width="60" class="mt-2" />
            </template>
            <template v-slot:header.Experian="{}">
              <experian-img :width="50" class="mt-2" />
            </template>
            <template v-slot:header.TransUnion="{}">
              <transunion-img :width="60" class="mt-2" />
            </template>
          </v-data-table>
        </v-card>
        <div class="d-flex mt-4">
          <v-btn outlined color="success" :to="{ name: 'dispute-info' }"
            >View Dispute Items</v-btn
          >
          <v-btn
            outlined
            color="success"
            :to="{ name: 'import-audit' }"
            class="ml-4"
            >Import Credit Reports</v-btn
          >
        </div>
      </v-col>
      <v-col cols="12" xl="4">
        <div class="d-flex justify-center">
          <div style="max-width: 300px">
            <apexchart :options="chartOptions" :series="series" />
            <div class="subtitle-1 font-weight-bold">
              {{ positivePercentage }} %
            </div>
            <v-progress-linear
              height="12"
              class="rounded-xl"
              color="green"
              :value="positivePercentage"
            ></v-progress-linear>
            <select-field
              hide-details
              class="mt-5"
              v-model="bureau"
              :items="bureaus"
            />
            <v-btn
              text
              color="primary"
              v-if="hasPermission('customers', 'saved-letters')"
              class="mt-3 justify-start"
              :to="{
                name: 'client-saved-letters',
              }"
              >Client Saved Letters</v-btn
            >
            <v-btn
              text
              color="primary"
              :to="{
                name: 'client-saved-reports',
              }"
              class="mt-3 justify-start"
              >Client Saved Reports</v-btn
            >
            <client-document-storage />
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { getHeaders } from "@/assets/utils/common.utils";
import EquifaxImg from "../EquifaxImg.vue";
import ExperianImg from "../ExperianImg.vue";
import TransunionImg from "../TransunionImg.vue";
import { getDocs, limit, orderBy, query, where } from "firebase/firestore";
import { colCreditReports } from "@/assets/utils/firebase.utils";
import { mapDocs } from "@/assets/utils/doc.utils";
import _ from "lodash";
import SelectField from "@/components/ui/form/SelectField.vue";
import ClientDocumentStorage from "./ClientDocumentStorage.vue";
let bureaus = ["Equifax", "Experian", "TransUnion"];
let statusList = [
  "Positive",
  "Negative",
  "In Dispute",
  "Deleted",
  "Updated",
  "Unspecified",
];
export default {
  components: {
    EquifaxImg,
    ExperianImg,
    TransunionImg,
    SelectField,
    ClientDocumentStorage,
  },
  data() {
    return {
      loading: true,
      report: {},
      bureau: "All",
    };
  },
  computed: {
    bureaus() {
      return ["All", ...bureaus];
    },
    positivePercentage() {
      let green = this.series[0] || 0;
      let deleted = this.series[1] || 0;
      let positive = green + deleted;
      if (positive == 0) return 0;
      let total = this.series.reduce((p, c) => {
        p += c;
        return p;
      }, 0);
      return ((positive / total) * 100).toFixed(0);
    },
    series() {
      let seriesMap = {
        green: ["Positive", "Updated"],
        "#0F35FF": ["Deleted"],
        red: ["Negative"],
        orange: ["In Dispute"],
        blue: ["Unspecified"],
      };
      let series = Object.keys(seriesMap).map((key) => {
        let count = 0;
        seriesMap[key].forEach((i) => {
          let stats = _.get(this.statusStats, i, {});
          if (this.bureau == "All") {
            bureaus.forEach((bureau) => {
              if (stats[bureau]) {
                count = stats[bureau].length + count;
              }
            });
          } else {
            if (stats[this.bureau]) {
              count = stats[this.bureau].length + count;
            }
          }
        });
        return count;
      });
      return series;
    },
    chartOptions() {
      let total = this.series.reduce((p, c) => {
        p += c;
        return p;
      }, 0);
      return {
        chart: {
          width: "100%",
          type: "pie",
        },
        labels: [
          "Positive,Updated",
          "Deleted",
          "Negative",
          "In Dispute",
          "Unspecified",
        ],
        tooltip: {
          y: {
            formatter: function (value) {
              let per = (value / total) * 100;
              return per.toFixed(0) + "%";
            },
          },
        },
        legend: {
          show: false,
        },
        colors: ["#4caf50", "#0F35FF", "#ff5252", "#ffeb3b", "#589ee4"],
        fill: {
          colors: ["#4caf50", "#0F35FF", "#ff5252", "#ffeb3b", "#589ee4"],
        },
        dataLabels: {
          enabled: false,
        },
      };
    },
    headers() {
      let doc = {
        status: "",
        Equifax: "",
        Experian: "",
        TransUnion: "",
      };
      return getHeaders(doc).map((i) => ({
        ...i,
        class: `subtitle-2 font-weight-medium ${
          i.value == "date" ? "w-40" : "w-20"
        }`,
      }));
    },
    statusStats() {
      let statusStats = {};
      (this.report.accounts || []).forEach((account) => {
        (account.list || []).forEach((item) => {
          if (statusStats[item.status] == undefined) {
            statusStats[item.status] = {
              Experian: [],
              Equifax: [],
              TransUnion: [],
            };
          }
          statusStats[item.status][item.bureau].push(item.status);
        });
      });
      return statusStats;
    },
    items() {
      return statusList.map((status) => {
        let doc = { status };
        bureaus.forEach((bureau) => {
          let list = _.get(this.statusStats, `${status}.${bureau}`, []);
          doc[bureau] = list.length;
        });
        return doc;
      });
    },
  },
  methods: {
    getStatusClass(value) {
      switch (value) {
        case "Unspecified":
          return "primary";
        case "In Dispute":
          return "yellow";
        case "Negative":
          return "error";
        case "Deleted":
          return "status-deleted";
        default:
          return "success";
      }
    },

    async fetchReport() {
      try {
        this.loading = true;
        let reportQuery = query(
          colCreditReports,
          orderBy("created_at", "desc"),
          where("customer", "==", this.$route.params.id),
          limit(1)
        );
        let reports = mapDocs((await getDocs(reportQuery)).docs);
        if (reports.length > 0) {
          this.report = reports[0];
        }
        this.loading = false;
      } catch (error) {
        console.log("Error while fetching the report");
        this.handleError(error);
      }
    },
  },
  mounted() {
    this.fetchReport();
  },
};
</script>

<style>
.status-deleted {
  background: #0f35ff;
}
</style>
